/**
 * A Building Type definition
 * @typedef {Object} BuildingType
 * @property {number} id
 * @property {string} name
 * @property {string} created_at
 * @property {string} updated_at
 */

export default {
  buildingTypes: [],
  buildingTypesState: null,

  activeBuildingTypes: null,
  activeBuildingTypesState: null,
  activeBuildingTypesErrors: [],
  activeBuildingTypesValid: false,
  activeBuildingTypesModifiedFields: {},
};
