export default {
  IS_PROD: import.meta.env.NODE_ENV === 'production',
  NODE_ENV: import.meta.env.NODE_ENV,
  VERSION: import.meta.env.VITE_VERSION,
  BUILD_DATE: new Date(parseInt(import.meta.env.VITE_BUILD_DATE, 10)),
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  RELEASE: import.meta.env.VITE_RELEASE,
  RELEASE_NOTES_URL: import.meta.env.VITE_RELEASE_NOTES_URL,
  API_URL: import.meta.env.VITE_API_URL,
  SESSION_COOKIE_EXPIRATION_LENGTH: parseInt(
    import.meta.env.VITE_SESSION_COOKIE_EXPIRATION_LENGTH,
    10,
  ),
  UPDATE_CHECK_INTERVAL: 5000,
  AUTO_UPDATE_TIMEOUT: parseInt(import.meta.env.VITE_AUTO_UPDATE_TIMEOUT, 10),

  // prevents dropdowns causing horizontal scrolling on dropdowns near the right edge on IE11.
  POPPER_CONFIG: { modifiers: { computeStyle: { gpuAcceleration: false } } },
};
