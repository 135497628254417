/**
 * A siteType definition
 * @typedef {Object} siteType
 * @property {number} id -
 * @property {string} name -
 * @property {string[]} permissions -
 * @property {string} created_at -
 * @property {string} updated_at -
 */

export default {
  siteTypes: [],
  siteTypesStatus: null,
};
