<template>
  <div class="login">
    <b-form @submit.prevent="onSubmit">
      <div class="fields-wrapper">
        <b-alert v-if="errors && errors.length > 0" variant="danger" show>
          <h4 class="alert-heading">
            {{ $t('common.forms.errorTitle') }}
          </h4>
          <ul class="list-unstyled mb-0">
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>
        </b-alert>

        <b-form-group v-if="requireOldPassword" label-for="oldPassword">
          <template v-slot:label>
            <Required>
              {{ $t('components.auth.login.password.oldPassword') }}
            </Required>
          </template>

          <b-form-input
              id="oldPassword"
              v-model.trim="v$.oldPassword.$model"
              class="field-input"
              type="password"
              :state="v$.oldPassword.$dirty ? !v$.oldPassword.$invalid : null"
          />

          <b-form-invalid-feedback
              v-if="v$.oldPassword.$error"
              id="oldPasswordFeedback"
              :state="v$.oldPassword.$error"
          >
            <p v-if="v$.oldPassword.required === false">
              {{
                $t('common.forms.validation.required', {
                  field: $t('components.auth.login.password.oldPassword'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="password">
          <template v-slot:label>
            <Required>
              {{ $t('components.auth.login.password.newPassword') }}
            </Required>
          </template>

          <password v-model="password" @validationchange="onPasswordValidation" />
        </b-form-group>

        <b-form-group label-for="confirmPassword">
          <template v-slot:label>
            <Required>
              {{ $t('components.auth.login.password.passwordConfirmation') }}
            </Required>
          </template>

          <b-form-input
              id="confirmPassword"
              v-model.trim="v$.confirmPassword.$model"
              :state="v$.confirmPassword.$dirty ? !v$.confirmPassword.$invalid : null"
              class="field-input"
              type="password"
          />

          <b-form-invalid-feedback
              v-if="v$.confirmPassword.$error"
              id="form-feedback"
              :state="v$.confirmPassword.$error"
          >
            <p v-if="v$.confirmPassword.required === false">
              {{
                $t('common.forms.validation.required', {
                  field: $t('components.auth.login.password.passwordConfirmation'),
                })
              }}
            </p>
            <p v-if="v$.confirmPassword.sameAsPassword === false">
              {{
                $t('common.forms.validation.sameAs', {
                  field: $t('components.auth.login.password.passwordConfirmation'),
                  sameField: $t('components.auth.login.password.password'),
                })
              }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BAlert, BFormInvalidFeedback } from 'bootstrap-vue';
import { required, sameAs } from '@vuelidate/validators';
import Password from '@/ux/form/Password.vue';
import types from '@/store/types/authorization.js';
import Required from '@/ux/form/Required.vue';
import AuthorizationAPI from '@/services/AuthorizationAPI.js';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'ChangePassword',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BAlert,
    Password,
    Required,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    requireOldPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      errors: [],
      success: null,
      isLoading: false,
      passwordComponentValid: false,
    };
  },
  validations() {
    const validations = {
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.password),
      },
    };
    if (this.requireOldPassword) {
      validations.oldPassword = {
        required,
      };
    }

    return validations;
  },
  computed: {
    validationState() {
      return this.passwordComponentValid && !this.v$.$error && !this.v$.$invalid;
    },
  },
  watch: {
    validationState: function emitValidationChange() {
      this.$emit('validation', this.validationState);
    },
    isLoading() {
      this.$emit('loading', this.isLoading);
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      AuthorizationAPI.changePassword(this.userId, this.password, this.oldPassword)
          .then(() => {
            this.errors = [];
            this.isLoading = false;
            this.$store.commit(types.mutations.SET_SESSION_STATUS, this.$enums.SESSION.LOGGED_IN);
            this.$emit('passwordchanged');
          })
          .catch((error) => {
            this.success = '';
            this.errors =
                typeof error.response.data.message === 'string'
                    ? [error.response.data.message]
                    : error.response.data.message;
            this.isLoading = false;
          });
    },
    onPasswordValidation(valid) {
      this.passwordComponentValid = valid;
      this.$emit('validation', this.validationState);
    },
  },
};
</script>

<style lang="scss" scoped>
.field-label {
  margin-right: 0.2rem;
}

.field-input {
  margin-top: 0.5rem;
}

.btn-placeholder {
  width: 4.5rem;
}

.modal-body {
  display: flex;
  flex-direction: column;

  .fields-wrapper {
    flex: 1;
  }

  .bottom-toolbar {
    padding: 1rem 1rem 0;
  }
}
</style>
