export default {
  state: {
    /**
     * A Budget definition
     * @typedef {Object} Budget
     * @property {number} id -
     * @property {string} name -
     * @property {string} type -
     * @property {number} financial_period_id -
     * @property {number} site_id -
     * @property {number} amount -
     * @property {boolean} enabled -
     * @property {date} created_at -
     * @property {date} updated_at -
     */
    budget: () => ({
      id: null,
      name: null,
      type: null,
      financial_period_id: null,
      site_id: null,
      amount: null,
      enabled: true,
      created_at: null,
      updated_at: null,
    }),
  },

  getters: {},

  actions: {
    GET_BUDGET: 'GET_BUDGET',
    UPDATE_BUDGET: 'UPDATE_BUDGET',
    CREATE_BUDGET: 'CREATE_BUDGET',
    DELETE_BUDGET: 'DELETE_BUDGET',
  },

  mutations: {
    SET_ACTIVE_BUDGET: 'SET_ACTIVE_BUDGET',
    SET_ACTIVE_BUDGET_STATE: 'SET_ACTIVE_BUDGET_STATE',
    SET_ACTIVE_BUDGET_ERRORS: 'SET_ACTIVE_BUDGET_ERRORS',
    SET_ACTIVE_BUDGET_VALID: 'SET_ACTIVE_BUDGET_VALID',
    UPDATE_ACTIVE_BUDGET_MODIFIED_FIELDS: 'UPDATE_ACTIVE_BUDGET_MODIFIED_FIELDS',
    CLEAR_ACTIVE_BUDGET_MODIFIED_FIELDS: 'CLEAR_ACTIVE_BUDGET_MODIFIED_FIELDS',

    SET_BUDGETS_FILTER: 'SET_BUDGETS_FILTER',
  },

  type_options: {},
};
