/* eslint-disable no-unused-vars */
import axios from 'axios';
import createFormData from '@/util/createFormData.js';
import createAPIParams from '@/util/createAPIParams.js';
import FileExportAPI from '@/services/FileExportAPI.js';
import getFileUploadStatus from '@/util/getFileUploadStatus.js';
/**
 * Interface to the WorkOrder API endpoints
 * @class
 * @exports src/services/WorkOrderAPI
 */

class WorkOrderAPI {
  /**
   * Retrieves all WorkOrders counts
   * @param params
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { workOrder: {object[]}, meta: {object}, links: {object} }
   */
  static async getWorkOrderCounts(params, filter) {
    const response = await axios({
      method: 'GET',
      url: 'work_order/stats',
      responseType: 'json',
      params: createAPIParams('all', null, null, filter, params),
    });

    return response.data;
  }

  /**
   * Retrieves the specified WorkOrder's details
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to load
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async getWorkOrder(workOrderId) {
    const response = await axios({
      method: 'GET',
      url: `work_order/${workOrderId}`,
      responseType: 'json',
    });
    return response.data.data;
  }

  /**
   * Retrieves the specified WorkOrder's files
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to load
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async getWorkOrderFiles(workOrderId) {
    const response = await axios({
      method: 'GET',
      url: `work_order/${workOrderId}/files`,
      responseType: 'json',
      params: {
        include: ['attachments', 'comments'].join(','),
      },
    });

    return response.data.data;
  }

  /**
   * Post a workorder file
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to load
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async addWorkOrderFiles(workOrderId, files, progressCallback) {
    const formData = createFormData({ files });
    const includeProgress = !!formData.values;

    const onUploadProgress = includeProgress
      ? (progressEvent) => {
          if (typeof progressCallback === 'function') {
            progressCallback(getFileUploadStatus(progressEvent.loaded, formData));
          }
        }
      : null;

    // run progress callback to start with to give base status
    if (includeProgress && typeof progressCallback === 'function') {
      progressCallback(getFileUploadStatus(0, formData));
    }

    const response = await axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'Post',
      url: `work_order/${workOrderId}/files`,
      responseType: 'json',
      onUploadProgress,
      data: formData,
    });

    return response.data.data;
  }

  /**
   * Retrieves all WorkOrders for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @param {Object} additionalQueryStringParams An object with key value pairs of query string parameters.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { workOrder: {object[]}, meta: {object}, links: {object} }
   */
  static async getWorkOrders(page, sort, direction, filter, additionalQueryStringParams) {
    const response = await axios({
      method: 'GET',
      url: 'work_order',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter, additionalQueryStringParams),
    });

    return response.data;
  }

  /**
   * Exports a file wiht the give criteria
   * @param {String} fileType The fileType to load
   * @param {String} fileName The fileName to save
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { sites: {object[]}, meta: {object}, links: {object} }
   */
  static async exportWorkOrders(fileType, fileName, sort, direction, filter) {
    const params = createAPIParams(null, sort, direction, filter);
    delete params.page;
    params.fileType = fileType;
    FileExportAPI.handleFileExport('work_order/export', params, fileType, fileName);
  }

  /**
   * Retrieves all WorkOrders that aren't already linked to the specified workOrder
   * (note that a workOrder can't be linked to itself)
   * @param  {number}  workOrderId    The workOrder id
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { workOrder: {object[]}, meta: {object}, links: {object} }
   */
  static async getAvailableToLinkTo(workOrderId, page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: `work_order/${workOrderId}/available`,
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * @summary Hits the create WorkOrder endpoint.
   * @desc Sends the given workOrder data to the create endpoint
   * @param  {WorkOrder}  workOrder A {@link WorkOrder} object to be saved
   * @return {Promise}      Resolves to the returned full WorkOrder object
   */
  static async createWorkOrder(workOrder) {
    const response = await axios({
      method: 'POST',
      url: 'work_order',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'json',
      data: createFormData(workOrder),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create WorkOrder endpoint.
   * @desc Sends the given workOrder data to the create endpoint
   * @param  {WorkOrder}  workOrder A {@link WorkOrder} object to be saved
   * @return {Promise}      Resolves to the returned full WorkOrder object
   */
  static async createPartOrder(workOrder) {
    const response = await axios({
      method: 'POST',
      url: 'parts_order',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'json',
      data: createFormData(workOrder),
    });

    return response.data.data;
  }

  /**
   * @summary Fetches the configuration for the external WO form.
   * @param {String} accessToken The token to provide authentication for external pages
   * @return {Promise}      Resolves to the returned WO form config object.
   */
  static async getExternalWorkOrderForm(accessToken) {
    const response = await axios({
      method: 'GET',
      url: `work_order_form/external/${accessToken}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create WorkOrder endpoint for an _external_ page - using the token supplied
   * @desc Sends the given workOrder data to the create endpoint
   * @param  {WorkOrder}  workOrder A {@link WorkOrder} object to be saved
   * @param {String} accessToken The token to provide authentication for external pages
   * @return {Promise}      Resolves to the returned full WorkOrder object
   */
  static async createWorkOrderExternal(workOrder, accessToken) {
    await axios({
      method: 'POST',
      url: `work_order_form/external/${accessToken}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'json',
      data: createFormData(workOrder),
    });

    return true;
  }

  /**
   * @summary Hits the PATCH WorkOrder endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  workOrderId The WorkOrder Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link WorkOrder} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full WorkOrder object
   */
  static async updateWorkOrder(workOrderId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `work_order/${workOrderId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * Retrieves the specified WorkOrder's possible transitions
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to load transitions for
   * @return {Promise}          Promise that resolves to an array of Transitions
   */
  static async getWorkOrderTransitions(workOrderId) {
    const response = await axios({
      method: 'GET',
      url: `work_order/${workOrderId}/transition`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Transitions the work order to it's next state
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async transitionWorkOrder(workOrderId, transitionId, geolocation) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/transition`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        geolocation,
      },
    });

    return response.data.data;
  }

  static async addComplianceDocument(endpoint, workOrderId, transitionId) {
    const response = await axios({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: {
        transition_id: transitionId,
      },
    });

    return response.data.data;
  }

  static async transitionAddFollowOnWorkOrder(endpoint, workOrderId, transitionId) {
    const response = await axios({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: {
        transition_id: transitionId,
      },
    });

    return response.data.data;
  }

  /**
   * Transitions the work order to it's next state
   * @method
   * @static
   * @param {string} endpoint Endpoint
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {string}  message    The message to attach to the transition
   * @param {array} files Any optionally attached files
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async transitionWorkOrderWithMessage(endpoint, workOrderId, transitionId, message, files) {
    const response = await axios({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: createFormData({
        transition_id: transitionId,
        message,
        files,
      }),
    });

    return response.data.data;
  }

  static async transitionWorkOrderAddEngineerTimelog(endpoint, workOrderId, transitionId, payload) {
    const response = await axios({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        work_order_id: workOrderId,
        ...payload,
      },
    });
  }

  static async addFollowOnQuote(endpoint, workOrderId, transitionId) {
    const response = await axios({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: {
        transition_id: transitionId,
      },
    });

    return response.data.data;
  }

  /**
   * Transitions the work order to it's next state
   * @method
   * @static
   * @param  {string}  endpoint        The endpoint to POST to
   * @param  {number}  transitionId    The Id of the transition to move to
   * @return {Promise} Promise that resolves to the response data - may not be {@link WorkOrder}
   *                   details.
   */
  static async handleSimpleCustomTransition(endpoint, transitionId) {
    const response = await axios({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: {
        transition_id: transitionId,
      },
    });

    return response.data.data;
  }

  /**
   * Assigns a work order type, service and engineer to a WorkOrder and moves it to a new state
   * @method
   * @static
   * @param  {number}  workOrderId      The workOrder id to transition
   * @param  {number}  transitionId     The Id of the transition to move to
   * @param  {number}  workOrderTypeId  The Id of the work order type to assign
   * @param  {number}  serviceId        The Id of the service to assign
   * @param  {number}  engineerId       The Id of the engineer to assign
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async assignWorkflowAndService(
    workOrderId,
    transitionId,
    workOrderTypeId,
    serviceId,
    engineerId,
  ) {
    const data = {
      transition_id: transitionId,
      work_order_type_id: workOrderTypeId,
      service_id: serviceId,
    };

    if (engineerId) {
      data.engineer_id = engineerId;
    }

    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_workflow_and_service`,
      responseType: 'json',
      data,
    });

    return response.data.data;
  }

  /**
   * Assigns an owner to a WorkOrder and moves it to the new transition
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {number}  ownerId    The Id of the owner to assign
   * @param  {string}  message    The message to attach
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async assignOwner(workOrderId, transitionId, ownerId, message) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_owner`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        owner_id: ownerId,
        message,
      },
    });

    return response.data.data;
  }

  /**
   * Assigns an Internal Engineer to a WorkOrder and moves it to the new transition
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {number}  engineerId    The Id of the engineer to assign
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async assignInternalEngineer(workOrderId, transitionId, engineerId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_internal_engineer`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        engineer_id: engineerId,
      },
    });

    return response.data.data;
  }

  /**
   * Assigns an Contractor to a WorkOrder and moves it to the new transition
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {number}  contractorId    The Id of the contractor to assign
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async assignContractor(workOrderId, transitionId, contractorId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_contractor`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        contractor_id: contractorId,
      },
    });

    return response.data.data;
  }

  /**
   * Assigns an Service Type to a WorkOrder and moves it to the new transition
   * @method
   * @param {number} workOrderId
   * @param {number} transitionId
   * @param {number} serviceTypeId
   * @returns {Promise<*>}
   */
  static async assignServiceType(workOrderId, transitionId, serviceTypeId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_service_type`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        service_type_id: serviceTypeId,
      },
    });

    return response.data.data;
  }

  /**
   * Assigns a service to a WorkOrder and moves it to the new transition
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {number}  serviceId    The Id of the service to assign
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async assignService(workOrderId, transitionId, serviceId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_service`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        service_id: serviceId,
      },
    });

    return response.data.data;
  }

  /**
   * Assigns an Engineer to a WorkOrder and moves it to the new transition
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {number}  engineerId    The Id of the engineer to assign
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async assignEngineer(workOrderId, transitionId, engineerId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/assign_engineer`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        engineer_id: engineerId,
      },
    });

    return response.data.data;
  }

  /**
   * Amends part order and moves it to the new transition
   * @method
   * @static
   * @param {number} workOrderId
   * @param {numbers} transitionId
   * @param {array} parts
   * @param {array} surcharges
   * @param {string} amendment_reason
   * @returns {Promise<*>}
   */
  static async amendOrder(workOrderId, transitionId, parts, surcharges, amendment_reason) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/amend_order`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        parts,
        surcharges,
        amendment_reason,
      },
    });

    return response.data.data;
  }

  /**
   * Amends part order and moves it to the new transition
   * @method
   * @static
   * @param {number} workOrderId
   * @param {numbers} transitionId
   * @param {array} parts
   * @param {string} delivery_details
   * @param {array} selected
   * @returns {Promise<*>}
   */
  static async dispatchOrder(workOrderId, transitionId, parts, selected, delivery_details) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/dispatch_order`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        parts,
        selected,
        delivery_details,
      },
    });

    return response.data.data;
  }

  /**
   * Amends part order and moves it to the new transition
   * @method
   * @static
   * @param {number} workOrderId
   * @param {numbers} transitionId
   * @param {array} parts
   * @param {string} comments
   * @param {array} selected
   * @returns {Promise<*>}
   */
  static async markDelivered(workOrderId, transitionId, parts, selected, comments) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/mark_delivered`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        parts,
        selected,
        comments,
      },
    });

    return response.data.data;
  }

  /**
   * Sets the compliant_date for a WorkOrder and moves it to the new transition
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {string}  compliantDate    The compliant date to set
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async setCompliantDate(workOrderId, transitionId, compliantDate, nextDueDate) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/set_compliant_date`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        compliant_date: compliantDate,
        next_due_date: nextDueDate,
      },
    });

    return response.data.data;
  }

  static async confirmCostTreatment(workOrderId, transitionId, data) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/confirm_cost_treatment`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        ...data,
      },
    });

    return response.data.data;
  }

  static async checkoutEngineers(workOrderId, transitionId, closureReason, visitNotes) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/checkout_engineers`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        closure_reason: closureReason,
        visit_notes: visitNotes,
      },
    });

    return response.data.data;
  }

  /**
   * Abandons a WorkOrder and moves to new transition state.
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId    The Id of the transition to move to
   * @param  {string}  reason    The reason it was abandoned
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async abandon(workOrderId, transitionId, reason) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/abandon`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        abandonment_reason: reason,
      },
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE WorkOrder endpoint.
   * @param  {String|Number}  workOrderId The WorkOrder Id to delete
   * @return {Promise}
   */
  static async deleteWorkOrder(workOrderId) {
    await axios({
      method: 'DELETE',
      url: `work_order/${workOrderId}`,
      responseType: 'json',
    });

    return true;
  }

  /**
   * Sets the work order billing status to submitted.
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @param  {Object}  data  Additional data to pass along with the request
   * @return {Promise}
   */
  static async submitCostsForApproval(workOrderId, data) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/submit_for_approval`,
      responseType: 'json',
      data,
    });

    return response.data.data;
  }

  /**
   * Returns the work order costs to draft and sets the work order billing status to draft.
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @return {Promise}
   */
  static async returnCostsToDraft(workOrderId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/return_to_draft`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Sets the work order to rejected status.
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @param  {Array} costIds The invoice lines to query
   * @param  {string} message The rejection message
   * @param  {Number}  billingSubmissionCount The work order's current billing_submission_count value
   * @return {Promise}
   */
  static async queryCosts(workOrderId, costIds, message, billingSubmissionCount) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/query`,
      responseType: 'json',
      data: {
        message,
        queried_cost_ids: costIds,
        billing_submission_count: billingSubmissionCount,
      },
    });

    return response.data.data;
  }

  /**
   * Sets the work order to rejected status.
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @param  {string} message The rejection message
   * @param  {Number}  billingSubmissionCount The work order's current billing_submission_count value
   * @return {Promise}
   */
  static async rejectCosts(workOrderId, message, billingSubmissionCount) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/reject`,
      responseType: 'json',
      data: {
        message,
        billing_submission_count: billingSubmissionCount,
      },
    });

    return response.data.data;
  }

  /**
   * Cancels a query/rejection and returns the work order to draft status
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @return {Promise}
   */
  static async cancelResponse(workOrderId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/cancel_response`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Approves the costs associated with a work order and assigns them to a new invoice.
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @param  {Object}  invoice  Object containing reference, description, (optional) closing_date
   * @param  {Number}  billingSubmissionCount The work order's current billing_submission_count value
   * @param  {number}  budgetId The work order's current budget_id value
   * @param  {number}  costCodeId The work order's current cost_code_id value
   * @return {Promise}
   */
  static async approveCostsToNewInvoice(workOrderId, invoice, billingSubmissionCount, budgetId, costCodeId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/approve_to_new_invoice`,
      responseType: 'json',
      data: {
        ...invoice,
        billing_submission_count: billingSubmissionCount,
        budget_id: budgetId,
        cost_code_id: costCodeId,
      },
    });

    return response.data.data;
  }

  /**
   * Approves the costs associated with a work order and assigns them to an existing invoice.
   * @method
   * @static
   * @param  {number}  workOrderId  The workOrder id
   * @param  {Object}  invoiceId  The invoice id
   * @param  {Number}  billingSubmissionCount The work order's current billing_submission_count value
   * @param  {number}  budgetId The work order's current budget_id value
   * @param  {number}  costCodeId The work order's current cost_code_id value
   * @return {Promise}
   */
  static async approveCostsToExistingInvoice(workOrderId, invoiceId, billingSubmissionCount, budgetId, costCodeId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/costs/approve_to_existing_invoice`,
      responseType: 'json',
      data: {
        invoice_id: invoiceId,
        billing_submission_count: billingSubmissionCount,
        budget_id: budgetId,
        cost_code_id: costCodeId,
      },
    });

    return response.data.data;
  }

  static async addRams(workOrderId, transitionId, ramsIds) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/add_rams`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        rams_ids: ramsIds,
      },
    });

    return response.data.data;
  }

  static async createStartWorkCheck(workOrderId, transitionId, workOrderStartWorkCheckId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/create_start_work_check`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        work_order_start_work_check_id: workOrderStartWorkCheckId,
      },
    });

    return response.data.data;
  }

  static async issueWorkPermit(workOrderId, transitionId, workPermitIds) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/issue_work_permit`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
        work_permit_ids: workPermitIds,
      },
    });

    return response.data.data;
  }

  static async confirmRemedialActions(workOrderId, transitionId) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/confirm_remedial_actions`,
      responseType: 'json',
      data: {
        transition_id: transitionId,
      },
    });

    return response.data.data;
  }

  static async getWorkOrderTasklists(filter) {
    const response = await axios({
      method: 'GET',
      url: 'work_order/task_list',
      params: createAPIParams('all', null, null, filter),
    });

    return response.data;
  }

  static async postTask(tasklistId, task) {
    const response = await axios({
      method: 'POST',
      url: `work_order/task_list/${tasklistId}/task`,
      data: task,
    });

    return response.data;
  }

  static async postWorkOrderTasklist(workOrderId, tasklist) {
    const response = await axios({
      method: 'POST',
      url: `work_order/${workOrderId}/task_list`,
      data: tasklist,
    });

    return response.data;
  }

  static async removeWorkOrderTask(taskId) {
    const response = await axios({
      method: 'DELETE',
      url: `work_order/task_list/task/${taskId}`,
    });
  }

  static async deleteWorkOrderTasklist(tasklistId) {
    await axios({
      method: 'DELETE',
      url: `work_order/task_list/${tasklistId}`,
    });
  }

  static async updateWorkOrderTasklist(tasklistId, field) {
    const response = await axios({
      method: 'PATCH',
      url: `work_order/task_list/${tasklistId}`,
      data: field,
    });
  }

  static async updateWorkOrderTask(taskId, field) {
    const response = await axios({
      method: 'PATCH',
      url: `work_order/task_list/task/${taskId}`,
      data: field,
    });
  }
}

export default WorkOrderAPI;
