import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the SiteType API endpoints
 * @class
 * @exports src/services/SiteTypeAPI
 */
class SiteTypeAPI {
  /**
   * Retrieves all the SiteTypes
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link SiteTypes} items.
   */
  static async getSiteTypes(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'site_type',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }
}

export default SiteTypeAPI;
