import axios from 'axios';
import { processFilterParameters } from '@/util/createAPIParams.js';
import JsonApiResponseConverter from 'json-api-response-converter';
import IndexConfig from "@/services/v2/IndexConfig";

class IndexAPI {
  static async fetch(route, config) {
    let params = {};

    params.sort = config.direction === IndexConfig.DESCENDING ? `-${config.sort}` : config.sort;

    if (config.page) {
      params['page[number]'] = config.page;
    }

    if (config.pageSize) {
      params['page[size]'] = config.pageSize;
    }

    // set a default page size if one hasn't been set
    if (config.page && !config.pageSize) {
      params['page[size]'] = 50;
    }

    Object.keys(config.fields).forEach((value) => {
      params[`fields[${value}]`] = config.fields[value].join(',');
    });

    if (config.includes.length) {
      params.include = config.includes.join(',');
    }

    if (config.filters) {
      params = {
        ...params,
        ...processFilterParameters(config.filters),
      };
    }

    if (config.withCount) {
      params['withCount'] = config.withCount;
    }

    const response = await axios({
      method: 'GET',
      url: `v2/${ route }`,
      responseType: 'json',
      params,
    });

    return {
      data: new JsonApiResponseConverter(response.data).formattedResponse,
      meta: {
        ...response.data.meta,
        ...await this.extractMetaInformation(response.data)
      },
    };
  }

  static async extractMetaInformation(responseData) {
    return responseData.data.map((item) => {
      const itemId = item.id;
      const relationshipsMeta = {};

      if (item.relationships) {
        for (const relationshipKey in item.relationships) {
          const relationship = item.relationships[relationshipKey];
          if (relationship.meta) {
            relationshipsMeta[relationshipKey] = {
              count: relationship.meta.count,
            };
          }
        }
      }

      return {
        id: itemId,
        relationships: relationshipsMeta,
      };
    });
  }
}

export default IndexAPI;
