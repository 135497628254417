<template>
  <div class="attachment-list text-left">
    <div v-if="uploadProgress && attachments.length > 0" class="progress-wrapper mt-4">
      <b-progress show-progress :max="100">
        <b-progress-bar :value="uploadProgress.percentageComplete">
          <span :key="uploadProgress.percentageComplete">
            {{ uploadProgress.percentageComplete }}%
          </span>
        </b-progress-bar>
      </b-progress>

      <div class="progress-details">
        <template v-if="uploadProgress.percentageComplete < 100">
          <span :key="uploadProgress.complete.length + 1 + attachments.length">
            {{
              $t('components.attachment.AttachmentList.uploadProgress', {
                complete: uploadProgress.complete.length + 1,
                total: attachments.length,
              })
            }}
          </span>
        </template>
        <template v-else>
          {{ $t('components.attachment.AttachmentList.uploadComplete') }}
        </template>
      </div>
    </div>

    <b-form-group :label="label" label-for="wo-attachments">
      <b-alert v-for="(err, index) in errors" :key="index" variant="danger" show>
        <font-awesome-icon :icon="['far', 'exclamation-triangle']" class="mr-1" />
        <span :key="err">{{ err }}</span>
      </b-alert>
      <ul>
        <li v-for="(file, index) in attachments" :key="index">
          <div class="d-flex align-items-center">
            <div class="name flex-fill" data-ref="preview-button" @click="onPreviewClick(file)">
              <span v-if="getThumbnailUrl(file)" class="thumbnail">
                <img :src="getThumbnailUrl(file)" />
              </span>

              <font-awesome-icon
                v-else
                :icon="['far', getFileIcon(file)]"
                class="type-icon text-primary"
              />
              <span :key="file.name" translate="no">{{ file.name }}</span>
            </div>
            <div class="action-wrapper">
              <IconButton
                v-if="removingFileId !== file.id"
                data-ref="delete-file"
                icon="times"
                :text="$t('components.attachment.AttachmentList.removeAttachment')"
                @click="onRemoveAttachmentClick(file)"
              />
              <b-spinner
                v-else
                small
                :label="$t('components.attachment.AttachmentList.removingAttachment')"
              />
            </div>
          </div>
          <div v-if="uploadProgress" :class="{ status: true, [getFileUploadStatus(file)]: true }">
            <template v-if="getFileUploadStatus(file) === 'complete'">
              <font-awesome-icon :icon="['far', 'check-circle']" class="fa-fw mr-1" />&nbsp;<!--
              -->{{ $t('components.attachment.AttachmentList.files.complete') }}
            </template>
            <template v-if="getFileUploadStatus(file) === 'uploading'">
              <font-awesome-icon :icon="['far', 'spinner']" class="fa-spin fa-fw mr-1" />&nbsp;<!--
              -->{{ $t('components.attachment.AttachmentList.files.uploading') }}
            </template>
            <template v-if="getFileUploadStatus(file) === 'pending'">
              <font-awesome-icon :icon="['far', 'hourglass-half']" class="fa-fw mr-1" />&nbsp;<!--
              -->{{ $t('components.attachment.AttachmentList.files.pending') }}
            </template>
          </div>
        </li>
      </ul>
      <FileDropbox
        v-if="!isMaxAttachments()"
        :add-label="addLabel"
        :saving="disabled"
        :accept="accept"
        :instructions="instructions"
        @upload-files="addFiles($event)"
      />
    </b-form-group>

    <AttachmentPreview
      v-if="showPreview"
      :attachment="previewedAttachment"
      :can-delete="true"
      @close="onClosePreviewClick"
      @delete-attachment="onRemoveAttachmentClick(previewedAttachment)"
    />
  </div>
</template>

<script>
import { BFormGroup, BAlert, BSpinner, BProgress, BProgressBar } from 'bootstrap-vue';
import MediaAPI from '@/services/MediaAPI.js';
import AttachmentPreview from '@/components/attachment/AttachmentPreview.vue';
import IconButton from '@/ux/IconButton.vue';
import isImage from '@/util/isImage.js';
import readFileAsync from '@/util/fileToBase64.js';
import FileDropbox from '@/ux/FileDropbox.vue';
import getFileIcon from '@/util/getFileIcon.js';
import AssetModelAPI from '@/services/AssetModelAPI';

export default {
  name: 'AttachmentList',
  components: {
    BFormGroup,
    BAlert,
    BSpinner,
    BProgress,
    BProgressBar,
    FileDropbox,
    AttachmentPreview,
    IconButton,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: [
        'image/*',
        'video/*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
      ].join(),
    },
    label: {
      type: String,
      default: 'Attachments',
    },
    id: {
      type: String,
      default: 'wo-attachments',
    },
    addLabel: {
      type: String,
      default: 'Upload a Photo or Video'
    },
    uploadProgress: {
      type: Object,
      default: null,
    },
    maxAttachments: {
      type: Number,
      default: null,
    },
    minAttachments: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      attachments: [],
      addedAttachments: [],
      previewedAttachment: null,
      errors: [],
      removingFileId: null,
      showPreview: false,
      maxFileSize: window.appConfig?.MAX_FILE_UPLOAD_SIZE.value ??
        window.appConfig?.MAX_FILE_UPLOAD_SIZE.default_value ?? 104857600,
    };
  },

  computed: {},

  watch: {
    value() {
      this.attachments = this.value;
    },
    attachments() {
      this.$emit('input', this.attachments);
    },
  },

  created() {
    this.attachments = this.value;
  },

  methods: {
    getThumbnailUrl(file) {
      if (!isImage(file)) {
        return undefined;
      }

      if (this.isPhantomFile(file)) {
        return file.url;
      }

      return file.conversions?.thumb;
    },
    onClosePreviewClick() {
      this.showPreview = false;
      this.previewedAttachment = null;
    },
    onPreviewClick(file) {
      this.showPreview = true;
      this.canDelete = file.can_delete;
      this.previewedAttachment = file;
    },
    async addFiles(fileList) {
      const files = [...fileList];
      files.forEach(async (file) => {
        const errors = this.isFileValid(file);
        if (errors === true) {
          // eslint-disable-next-line no-param-reassign
          file.url = await readFileAsync(file);
          this.addedAttachments.push(file);
          this.attachments = [...this.attachments, file];
        } else {
          this.errors = this.errors.concat(errors);
        }
      });
    },
    isFileValid(file) {
      if (file.size > this.maxFileSize) {
        return [
          this.$t('components.attachment.AttachmentList.tooLarge', {
            name: file.name,
            fileSize: this.maxFileSize / 1000000,
          }),
        ];
      }

      return true;
    },
    onAddAttachmentsButtonClick() {
      this.$refs.file.$refs.input.click();
    },
    onRemoveAttachmentClick(file) {
      if(this.minAttachments >= this.attachments.length) {
        this.errors = [];
        this.errors.push(this.$t('components.attachment.AttachmentList.minimumAttachmentsError'));
        return;
      }

      this.previewedAttachment = null;
      if (this.isPhantomFile(file)) {
        this.doLocalFileRemove(file);
      } else {
        this.doRemoteFileRemove(file);
      }
    },
    doLocalFileRemove(file) {
      const index = this.attachments.indexOf(file);

      this.attachments.splice(index, 1);

      this.$emit('deleted', this.attachments);
    },
    async doRemoteFileRemove(file) {
      try {
        this.removingFileId = file.id;
        this.errors = [];

        await MediaAPI.deleteMedia(file.id);

        this.doLocalFileRemove(file);
      } catch (error) {
        this.removingFileId = null;

        let message = this.$t('components.attachment.AttachmentList.removeError');

        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message; // eslint-disable-line prefer-destructuring
        } else {
          message = error.message || message;
        }

        this.errors = Array.isArray(message) ? message : [message];
      } finally {
        this.removingFileId = null;
      }
    },
    isPhantomFile(file) {
      return this.addedAttachments.indexOf(file) >= 0;
    },
    isImage,
    readFileAsync,
    getFileIcon,
    getFileUploadStatus(file) {
      let status = 'uploading';

      if (!this.uploadProgress) {
        return '';
      }

      if (this.uploadProgress.complete.indexOf(file) >= 0) {
        status = 'complete';
      } else if (this.uploadProgress.pending.indexOf(file) >= 0) {
        status = 'pending';
      }

      return status;
    },
    isMaxAttachments() {
      if (!this.maxAttachments) {
        return false;
      }
      return this.attachments.length >= this.maxAttachments;
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-list {
  .hidden-input {
    display: none;
  }

  ul {
    padding: 0;
    margin: 1rem 0;

    li {
      min-height: 2.375rem;
      list-style: none;
      margin: 0.5rem 0;
      padding-left: 1rem;

      .name {
        cursor: pointer;
        svg {
          min-width: 2rem;
        }
      }

      .type-icon {
        font-size: 1.25rem;
        margin-right: 0.5rem;
      }

      .thumbnail {
        display: inline-block;
        width: 2rem;
        margin-right: 0.5rem;
        img {
          max-width: 100%;
        }
      }

      .action-wrapper {
        text-align: center;
        min-width: 2.25rem;
      }

      .status {
        padding-left: 0.45rem;
        font-size: 0.85rem;
        font-weight: bold;

        &.uploading {
          color: #3899f2;
        }

        &.pending {
          color: #b5b5b5;
        }

        &.complete {
          color: #15b54c;
        }
      }
    }
  }

  .progress-wrapper {
    .progress {
      height: 1.5rem;
      margin-top: 0.3rem;
      border-radius: 0.4rem;
      background-color: #d7d7d8;

      .progress-bar {
        background-color: #3899f2;
        text-align: right;
        padding-right: 0.4rem;
        font-weight: bold;
      }
    }

    .progress-details {
      color: #777777;
      text-align: center;
      font-size: 0.8rem;
      padding-top: 0.3rem;
    }
  }
}
</style>
