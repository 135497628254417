export default {
  getters: {},

  actions: {
    GET_BUILDING_TYPES: 'GET_BUILDING_TYPES',
  },

  mutations: {
    SET_BUILDING_TYPES: 'SET_BUILDING_TYPES',
    SET_BUILDING_TYPES_STATUS: 'SET_BUILDING_TYPES_STATUS',
  },
};
