import axios from 'axios';
import createFormData from '@/util/createFormData.js';

/**
 * Interface to the Expense API endpoints
 * @class
 * @exports src/services/ExpenseAPI
 */
class ExpenseAPI {
  /**
   * Retrieves all Invoices for the given Work Order
   * @param {Number} workOrderId The Work Order ID
   * @return {Promise} Promise that resolves to the api response.
   * In format: { data: {object[]}, meta: {object}, links: {object} }
   */
  static async getWorkOrderExpenses(workOrderId) {
    const data = await ExpenseAPI.getExpenses('all', 'created_at', 'asc', {
      'WorkOrder.id': workOrderId,
    });

    return data.data;
  }

  /**
   * Retrieves all the expenses
   * @method
   * @static
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { data: {object[]}, meta: {object}, links: {object} }
   */
  static async getExpenses(page, sort, direction, filter) {
    let directionSort = null;

    // if sort field isn't provided then don't set it
    if (sort) {
      directionSort = direction === 'DESC' ? `-${sort}` : sort;
    }

    const processedFilter = {};

    // if filter is falsey then don't process it
    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        processedFilter[`filter[${key}]`] = Array.isArray(value) ? value.join(',') : value;
      });
    }

    // create params object
    const params = {
      page,
      ...processedFilter,
    };

    // add sort if present
    if (directionSort) {
      params.sort = directionSort;
    }

    const response = await axios({
      method: 'GET',
      url: 'expense',
      responseType: 'json',
      params,
    });

    return response.data;
  }

  /**
   * @summary Hits the create expense endpoint.
   * @desc Sends the given expense data to the create endpoint
   * @param  {Expense}  expense A {@link Expense} object to be saved
   * @return {Promise}      Resolves to the returned full Expense object
   */
  static async createExpense(expense) {
    const response = await axios({
      method: 'POST',
      url: 'expense',
      responseType: 'json',
      data: createFormData(expense),
    });

    return response.data.data;
  }

  /**
   * Retrieves the specified Expense's details
   * @method
   * @static
   * @param  {number}  expenseId    The expense id to load
   * @return {Promise}          Promise that resolves to the {@link Expense} details.
   */
  static async getExpense(expenseId) {
    const response = await axios({
      method: 'GET',
      url: `expense/${expenseId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Updates a expense for a WorkOrder
   * @method
   * @static
   * @param  {number}  expenseId     The expense request to update
   * @param  {number}  data               The data to pass
   * @return {Promise}          Promise that resolves to the {@link Expense} details.
   */
  static async updateExpense(expenseId, data) {
    // eslint-disable-line max-len
    const { files, ...noFiles } = data;

    const response = await axios({
      method: 'PATCH',
      url: `expense/${expenseId}`,
      responseType: 'json',
      data: noFiles,
    });

    if (files.length) {
      return this.addFiles(expenseId, files);
    }

    return response.data.data;
  }

  /**
   * Adds files to an expense for a WorkOrder
   * @method
   * @static
   * @param  {number}  expenseId     The expense request to update
   * @param  {number}  data               The data to pass
   * @return {Promise}          Promise that resolves to the {@link Expense} details.
   */
  static async addFiles(expenseId, files) {
    // eslint-disable-line max-len
    const response = await axios({
      method: 'POST',
      url: `expense/${expenseId}/files`,
      responseType: 'json',
      data: createFormData({
        files: files.filter((f) => f instanceof File),
      }),
    });

    return response.data.data;
  }

  /**
   * Deletes an expense for a WorkOrder
   * @method
   * @static
   * @param  {number}  expenseId     The expense request to update
   * @param  {number}  data               The data to pass
   * @return {Promise}          Promise that resolves to the {@link Expense} details.
   */
  static async deleteExpense(expenseId) {
    // eslint-disable-line max-len
    const response = await axios({
      method: 'DELETE',
      url: `expense/${expenseId}`,
      responseType: 'json',
    });

    return response.data.data;
  }
}

export default ExpenseAPI;
