import types from '../../types/siteType.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SITE_TYPES
   * @summary Updates the `siteTypes` property.
   * @method
   * @param {Object} state Current state
   * @param {User[]} siteTypes The loaded SiteTypes array
   */
  [m.SET_SITE_TYPES](state, siteTypes) {
    state.siteTypes = siteTypes;
  },

  [m.SET_SITE_TYPES_STATUS](state, status) {
    state.siteTypesStatus = status;
  },
};
