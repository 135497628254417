import types from '@/store/types/buildingType.js';
import APIError from '../../../errors/APIError.js';
import BuildingTypeAPI from '../../../services/BuildingTypeAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  async [a.GET_BUILDING_TYPES]({ commit, state }, { filters }) {
    commit(m.SET_BUILDING_TYPES_STATUS, API_STATUS.LOADING);

    try {
      const buildingTypes = await BuildingTypeAPI.getBuildingTypes(filters);

      commit(m.SET_BUILDING_TYPES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_BUILDING_TYPES, buildingTypes);
    } catch (error) {
      commit(m.SET_BUILDING_TYPES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Building Types. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.buildingTypes;
  },
};
