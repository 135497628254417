import axios from 'axios';
import JsonApiResponseConverter from 'json-api-response-converter';
import Serializer from "@/services/v2/Serializer";
import dayjs from "dayjs";

class PatchAPI {
  static async patch(route, id, data, type = null) {
    data.id = data.id || id;

    let formattedData = {};

    // reformat any dates into ISO 8601 format, which is required by our json api package
    Object.keys(data).forEach((value) => {
      const isDate = dayjs(data[value], 'YYYY-MM-DDTHH:mm:ss', true).isValid();
      formattedData[`${value}`] = isDate
        ? dayjs(data[value]).toISOString()
        : data[value];
    });

    const serializedData = (new Serializer()).serialize(type ?? route, formattedData).data;

    const response = await axios({
      method: 'PATCH',
      url: `v2/${ route }/${id}`,
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      responseType: 'json',
      data: {data: serializedData},
    });

    return {
      data: new JsonApiResponseConverter(response.data).formattedResponse
    }
  }
}

export default PatchAPI;
