import APIError from '../../../errors/APIError.js';
import types from '../../types/expense.js';
import ExpenseAPI from '../../../services/ExpenseAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Expenses
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {Expense[]} An array of {@link Expense} objects.
   */
  async [a.GET_EXPENSES]({ commit, state }) {
    commit(m.SET_EXPENSES_STATUS, API_STATUS.LOADING);

    try {
      const expenses = await ExpenseAPI.getExpenses();

      commit(m.SET_EXPENSES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_EXPENSES, expenses);
      // commit(m.SET_EXPENSES_META, expenses.meta);
    } catch (error) {
      commit(m.SET_EXPENSES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Expenses. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.expenses;
  },

  /**
   * Creates a new Expense
   * @param {Object} context
   * @param {object} expense A {@link Expense} object
   * @return {Expense} A {@link Expense} object.
   */
  async [a.CREATE_EXPENSE]({ commit, state }, { expense }) {
    try {
      commit(m.SET_ACTIVE_EXPENSE_ERRORS, []);
      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.SAVING);

      const expenseResponse = await ExpenseAPI.createExpense(expense);

      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_EXPENSE, expenseResponse);
      commit(m.ADD_LOCAL_EXPENSE, expenseResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Expense. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeExpense;
  },

  /**
   * Updates an existing Expense
   * @param {Object} context
   * @param {object} expense The {@link Expense} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Expense} A {@link Expense} object.
   */
  async [a.UPDATE_EXPENSE]({ commit, state }, { expense, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_EXPENSE_ERRORS, []);
      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.SAVING);

      const expenseResponse = await ExpenseAPI.updateExpense(expense.id, modifiedFields);

      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_EXPENSE, expenseResponse);
      commit(m.UPDATE_LOCAL_EXPENSE, expenseResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Expense. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeExpense;
  },

  /**
   * Deletes a Expense
   * @param {Object} context
   * @param {Expense} expense The {@link Expense} object to delete
   * @return {Expense} A {@link Expense} object.
   */
  async [a.DELETE_EXPENSE]({ commit }, { expense }) {
    try {
      commit(m.SET_ACTIVE_EXPENSE_ERRORS, []);
      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.DELETING);

      await ExpenseAPI.deleteExpense(expense.id);

      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_EXPENSE, null);

      commit(m.REMOVE_LOCAL_EXPENSE, expense);
    } catch (error) {
      commit(m.SET_ACTIVE_EXPENSE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Expense. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }
      throw new Error(message);
    }
    return true;
  },
};
