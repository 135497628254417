import types from '@/store/types/buildingType.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_BUILDING_TYPES
   * @summary Updates the `buildingTypes` property.
   * @method
   * @param {Object} state Current state
   * @param {BuildingType[]} buildingTypes The loaded Building Type array
   */
  [m.SET_BUILDING_TYPES](state, buildingTypes) {
    state.buildingTypes = buildingTypes;
  },

  [m.SET_BUILDING_TYPES_STATUS](state, status) {
    state.buildingTypesState = status;
  },
};
