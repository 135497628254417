import axios from 'axios';

function pad2(n) {
  return n < 10 ? `0${n}` : n;
}
function now() {
  const date = new Date();
  return (
    date.getFullYear().toString() +
    pad2(date.getMonth() + 1) +
    pad2(date.getDate()) +
    pad2(date.getHours()) +
    pad2(date.getMinutes()) +
    pad2(date.getSeconds())
  );
}

/**
 * Handler for File Export API endpoints
 * @class
 * @exports src/services/FileExportAPI
 */
class FileExportAPI {
  /**
   * Exports a file wiht the give criteria
   * @param {String} url The url to retrieve the file from
   * @param {String} params Query string params to pass
   * @param {String} fileType The fileType to load
   * @param {String} fileName The fileName to save
   * @return {Promise} Promise that resolves to the api response.
   */
  static async handleFileExport(url, params, fileType, fileName) {
    await axios({
      methods: 'GET',
      url,
      responseType: 'blob',
      params,
    }).then((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${fileName}-${now()}.${fileType}`);
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default FileExportAPI;
