import APIError from '../../../errors/APIError.js';
import types from '../../types/budget.js';
import BudgetAPI from '../../../services/BudgetAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Creates a new Budget
   * @param {Object} context
   * @param {object} budget A {@link Budget} object
   * @return {Budget} A {@link Budget} object.
   */
  async [a.CREATE_BUDGET]({ commit, state }, { budget }) {
    try {
      commit(m.SET_ACTIVE_BUDGET_ERRORS, []);
      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.SAVING);

      const budgetResponse = await BudgetAPI.createBudget(budget);

      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_BUDGET, budgetResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Budget. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeBudget;
  },

  /**
   * Updates an existing Budget
   * @param {Object} context
   * @param {object} budget The {@link Budget} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Budget} A {@link Budget} object.
   */
  async [a.UPDATE_BUDGET]({ commit, state }, { budget, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_BUDGET_ERRORS, []);
      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.SAVING);

      const budgetResponse = await BudgetAPI.updateBudget(budget.id, modifiedFields);

      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_BUDGET, budgetResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Budget. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeBudget;
  },

  /**
   * Deletes a Budget
   * @param {Object} context
   * @param {object} budget The {@link Budget} object to delete
   * @return {Budget} A {@link Budget} object.
   */
  async [a.DELETE_BUDGET]({ commit }, { budget }) {
    try {
      commit(m.SET_ACTIVE_BUDGET_ERRORS, []);
      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.DELETING);

      await BudgetAPI.deleteBudget(budget.id);

      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_BUDGET, null);
    } catch (error) {
      commit(m.SET_ACTIVE_BUDGET_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Budget. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
