export default {
  /**
   * @name g isActiveBudgetPhantom
   * @desc Returns true if the activeBudget is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveBudgetPhantom(state) {
    return !(state.activeBudget && state.activeBudget.id);
  },
};
