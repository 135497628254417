import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Budget API endpoints
 * @class
 * @exports src/services/BudgetAPI
 */
class BudgetAPI {
  /**
   * Retrieves all Budgets for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { budget: {object[]}, meta: {object}, links: {object} }
   */
  static async getBudgets(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'budget',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * @summary Hits the create Budget endpoint.
   * @desc Sends the given budget data to the create endpoint
   * @param  {Budget}  budget A {@link Budget} object to be saved
   * @return {Promise}      Resolves to the returned full Budget object
   */
  static async createBudget(budget) {
    const response = await axios({
      method: 'POST',
      url: 'budget',
      responseType: 'json',
      data: budget,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Budget endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  budgetId The Budget Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Budget} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Budget object
   */
  static async updateBudget(budgetId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `budget/${budgetId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Budget endpoint.
   * @param  {String|Number}  budgetId The Budget Id to delete
   * @return {Promise}
   */
  static async deleteBudget(budgetId) {
    await axios({
      method: 'DELETE',
      url: `budget/${budgetId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default BudgetAPI;
