class IndexConfig {
  constructor() {
    this.direction = IndexConfig.ASCENDING;
    this.fields = {};
    this.filters = {};
    this.includes = [];
    this.page = null;
    this.pageSize = null;
    this.sort = 'id';
    this.withCount = '';
  }

  setDirection(direction) {
    this.direction = direction;

    return this;
  }

  setFields(fields) {
    this.fields = fields;

    return this;
  }

  setFilters(filters) {
    this.filters = filters;

    return this;
  }

  setIncludes(includes) {
    this.includes = includes;

    return this;
  }

  setPage(page) {
    this.page = page;

    return this;
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize;

    return this;
  }

  setSort(sort) {
    this.sort = sort;

    return this;
  }

  setWithCount(fields) {
    this.withCount = fields;

    return this;
  }

  static get DESCENDING() {
    return 'DESC';
  }

  static get ASCENDING() {
    return 'ASC';
  }
}

export default IndexConfig;
