export default {
  filter: {},

  meta: {},

  activeBudget: null,
  activeBudgetState: null,
  activeBudgetErrors: [],
  activeBudgetValid: false,
  activeBudgetModifiedFields: {},
};
