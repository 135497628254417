export default {
  path: '/recurring-work-order',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Recurring Work Orders',
    storeModules: [
      'recurringWorkOrder',
      'workOrder',
      'serviceType',
      'site',
      'workOrderAvailableServices',
    ],
  },
  component: () => import('@/views/recurring-work-orders/RecurringWorkOrderHome.vue'),
  children: [
    {
      path: ':tab?',
      name: 'RecurringWorkOrders',
      component: () => import('@/views/recurring-work-orders/RecurringWorkOrderGroups.vue'),
      meta: {
        requiresAuth: true,
        permission: 'RecurringWorkOrder_View',
        layout: 'DefaultLayout',
      },
    },
    {
      path: 'view/:id/:pane?',
      name: 'RecurringWorkOrderDisplay',
      component: () => import('@/views/recurring-work-orders/RecurringWorkOrderDisplay.vue'),
      meta: {
        requiresAuth: true,
        permission: 'RecurringWorkOrder_View',
        layout: 'DefaultLayout',
        bcDynamic: true,
        bcLinkText: 'View',
        bcLoadingText: 'Loading Recurring Work Order...',
      },
    },
    {
      path: 'create',
      name: 'RecurringWorkOrderCreate',
      component: () => import('@/views/recurring-work-orders/RecurringWorkOrderCreate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'RecurringWorkOrder_Manage',
        bcLinkText: 'Create',
      },
    },
  ],
};
