import types from '../../types/siteType.js';
import SiteTypeAPI from '../../../services/SiteTypeAPI.js';
import API from '../../../enums/API.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all SiteTypes
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {SiteType[]} An array of {@link SiteType} objects.
   */
  async [a.GET_SITE_TYPES]({ commit, state }) {
    commit(m.SET_SITE_TYPES_STATUS, API.LOADING);

    try {
      const siteTypes = await SiteTypeAPI.getSiteTypes('all');

      commit(m.SET_SITE_TYPES_STATUS, API.SUCCESS);
      commit(m.SET_SITE_TYPES, siteTypes);
    } catch (error) {
      commit(m.SET_SITE_TYPES_STATUS, API.FAILURE);

      let message = 'An error occurred while fetching these Site Types. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.siteTypes;
  },
};
