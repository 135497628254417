import axios from 'axios';
import { processFilterParameters } from '@/util/createAPIParams.js';

/**
 * A BuildingType definition
 * @typedef {Object} BuildingType
 * @property {number} id -
 * @property {string} name -
 */

/**
 * Interface to the BuildingType API endpoints
 * @class
 * @exports src/services/BuildingTypeAPI
 */
class BuildingTypeAPI {
  /**
   * Retrieves all the Building Types
   * @method
   * @static
   * @return {Promise} Promise that resolves to an aray of {@link BuildingType} items.
   */
  static async getBuildingTypes(filters) {
    const response = await axios({
      method: 'GET',
      url: 'building_type',
      responseType: 'json',
      params: {
        ...processFilterParameters(filters),
      },
    });

    return response.data.data;
  }
}

export default BuildingTypeAPI;
