export default {
  getters: {},

  actions: {
    GET_SITE_TYPES: 'GET_SITE_TYPES',
  },

  mutations: {
    SET_SITE_TYPES: 'SET_SITE_TYPES',
    SET_SITE_TYPES_STATUS: 'SET_SITE_TYPES_STATUS',
  },
};
