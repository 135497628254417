<template>
  <div class="expansive-tree" :class="{'shadowed': this.shadowed}" v-bind="$attrs">
    <div v-if="showButtons || showFilter" :class="{'px-3 pt-3': this.shadowed}">
      <div v-if="showButtons" class="d-flex flex-column">
        <div class="d-flex justify-content-between">
          <div>
            <b-button
                variant="outline-secondary"
                size="sm"
                class="mr-2"
                @click="expandAll"
            >
              <span>{{ $t('common.forms.expandAll') }}</span>
            </b-button>
            <b-button
                variant="outline-secondary"
                size="sm"
                @click="collapseAll"
            >
              <span>{{ $t('common.forms.collapseAll') }}</span>
            </b-button>
          </div>

          <div v-if="allowMultiple">
            <b-button variant="outline-secondary" size="sm" class="mr-2" @click="clearSelection">
              Clear Selection
            </b-button>
            <slot name="selection-button-content" />
          </div>
        </div>
      </div>

      <div :class="showFilter ? 'mt-3' : ''" style="display: flex; align-items: center;">
        <b-form-input v-model="filter" :disabled="loading" placeholder="Search the tree" />
        <div
          v-if="showHeaderAction"
          class="icon action-icon fa-ellipsis-v ml-3 mr-1 bold"
          @click="(event) => $emit('header-action', event)">
        </div>
      </div>
    </div>

    <div ref="loading" class="position-relative p-3">
      <PrimeSkeleton height="10rem" />
      <div class="loading-message text-muted">{{ $t('common.searching') }}</div>
    </div>

    <ul ref="tree" class="root"></ul>

    <div v-if="allowMultiple" class="d-flex border-top">
      <div v-if="selectionCount" class="mt-2">
        <small class="text-muted">{{ selectionCount }} selected</small>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import "@fortawesome/fontawesome-pro/scss/light.scss";
import "@fortawesome/fontawesome-pro/scss/regular.scss";
import "@fortawesome/fontawesome-pro/scss/solid.scss";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import {BButton, BFormInput} from "bootstrap-vue";
import {capitalize} from "lodash/string";
import {lowerCase} from "lodash";
import PrimeSkeleton from "primevue/skeleton/Skeleton.vue";

export default {
  name: 'Tree',
  inject: ['mq'],
  components: {
    BButton,
    BFormInput,
    PrimeSkeleton
  },
  props: {
    // array of objects with at least 'id', 'type'
    preSelected: {
      type: Array,
      default: () => [],
    },
    // array of objects with 'id', 'type' (see type below), 'label' (see labelField below)
    tree: {
      type: Array,
      default: () => [],
    },
    // specify the label field, e.g. 'name', 'title'
    labelField: {
      type: [String, Function],
      default: () => 'label',
    },
    // specify the type
    type: {
      type: Function,
      default: (node) => node.type,
    },
    // specify the icon name, e.g. 'house', 'building'
    icon: {
      type: Function,
      default: (node) => node.icon,
    },
    // provide an array of objects with 'type', 'icon'
    iconTypeMap: {
      type: Object,
      default: () => {},
    },
    headerActionIcon: {
      type: Boolean,
      default: false,
    },
    // provide an icon name to use for the action, e.g. 'edit', 'ellipsis'
    actionIcon: {
      type: String,
      default: null,
    },
    // provide an icon name to use for the action, e.g. 'edit', 'ellipsis'
    actionIf: {
      type: Function,
      default: (node) => true,
    },
    // expand the node as default if
    expandIf: {
      type: Function,
      default: (node) => false,
    },
    // make the node selectable if
    selectableIf: {
      type: Function,
      default: (node) => true,
    },
    // make the node selectable if
    stepThroughIf: {
      type: Function,
      default: (node) => false,
    },
    // make the node have disabled styling if (this doesn't affect being selectable)
    disabledStylingIf: {
      type: Function,
      default: (node) => false,
    },
    // single or multiple selection is supported, and icons adjust accordingly
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    // expand all / collapse all buttons
    showButtons: {
      type: Boolean,
      default: true,
    },
    // show the filter / search bar
    showFilter: {
      type: Boolean,
      default: true,
    },
    // can be used to show a flag / badge next to the label to indicate the type
    // generally used without icons, but can be used together
    showTypeFlags: {
      type: Boolean,
      default: false,
    },
    // scroll to the selected item, only applied when allowMultiple = false
    scrollToSelected: {
      type: Boolean,
      default: true,
    },
    // removes any unselectable nodes from the tree, unless they have any selectable recursive children
    showSelectableNodesOnly: {
      type: Boolean,
      default: false,
    },
    // can be used to remove checkboxes to use the tree as a navigation panel
    showCheckboxes: {
      type: Boolean,
      default: true,
    },
    showHeaderAction: {
      type: Boolean,
      default: false,
    },
    shadowed: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update',
    'node-select',
    'node-unselect',
    'node-action',
    'pre-node-expansion',
    'post-node-expansion'
  ],
  data() {
    return {
      loading: false,
      selected: [],
      filter: null,
      treeContainer: null,
    };
  },
  computed: {
    selectionCount() {
      return this.selected.length;
    },
    selectionMode() {
      return this.allowMultiple ? 'multiple' : 'single';
    },
  },
  watch: {
    tree: {
      handler() {
        this.rebuild();
      },
    },
    filter(value) {
      this.debouncedFilterWatch(value);
    },
  },
  created() {
    this.debouncedFilterWatch = debounce((value) => {
      this.onFilter(value);
    }, 500);
  },
  mounted() {
    this.rebuild();

    this.$nextTick(() => {
      setTimeout(() => this.goScrollToSelected(), 700);
    });
  },
  methods: {
    clearSelection() {
      this.selected = [];
      this._removeAllSelected();
      this.$emit('clear-selection');
    },
    selectAllOfType(type) {
      this.selected = this.selected.filter((node) => node.type !== type);
      this._removeAllSelected(type);

      this.$refs.tree.querySelectorAll(`li[data-type='${type}']`).forEach((nodeContainer) => {
        const id = nodeContainer.dataset.id;
        this._toggleSelected(id, type, true);
      });
      this.resetToSelected();
    },
    getSteppedOnIds() {
      const sets = [];
      this.$refs.tree.querySelectorAll('.stepped-into').forEach((node) => {
        sets.push(node.dataset);
      });

      return sets;
    },
    setSteppedOnIds(steppedOnIds) {
      steppedOnIds.forEach((steppedOnId) => {
        const nodeContainer = this._getNodeContainerByIdType(steppedOnId.id, steppedOnId.type);
        if (nodeContainer) {
          if (!nodeContainer.classList.contains('stepped-into')) {
            nodeContainer.classList.add('stepped-into');
          }
        }
      });
    },
    getExpandedIds() {
      const sets = [];
      this.$refs.tree.querySelectorAll('.expanded').forEach((node) => {
        sets.push(node.dataset);
      });

      return sets;
    },
    setExpandedIds(expandedIds) {
      expandedIds.forEach((expandedId) => {
        const nodeContainer = this._getNodeContainerByIdType(expandedId.id, expandedId.type);
        if (nodeContainer) {
          if (!nodeContainer.classList.contains('expanded')) {
            nodeContainer.classList.add('expanded');
          }
        }
      });
    },
    setLoadingState() {
      this.loading = true;
      this.treeContainer.classList.add('d-none');
      this.$refs.loading.classList.remove('d-none');
    },
    unsetLoadingState() {
      this.loading = false;
      this.$refs.loading.classList.add('d-none');
      this.treeContainer.classList.remove('d-none');
    },
    async rebuild() {
      this.treeContainer = this.$refs.tree;
      this.setLoadingState();

      this.treeContainer.classList.add(this.selectionMode);
      this.treeContainer.innerHTML = '';
      this._createNodeElements(this.treeContainer, this.tree);
      await this.setSelected();
      if (this.showSelectableNodesOnly) {
        this.removeUnselectableNodes();
      }

      this.unsetLoadingState();
    },
    // builds up the following markup for a single node of the tree
    //
    // <li class="expandable? expanded? selectable? selected? hidden? filter-hidden? disabled?" data-id="{{ id }}" data-type="{{ type }}" data-label="{{ label }}">
    //   <div class="node" data-id="{{ id }}">
    //     <div className="icon expanded-icon filter-hidden?" data-id="{{ id }}"></div>
    //     <div className="label-container" data-id="{{ id }}">
    //       ?<div className="icon label-icon" data-id="{{ id }}"></div>
    //       ?<div className="label-flag" data-id="{{ id }}"></div>
    //       <div class="label">{{label}}</div>
    //       ?<div className="icon select-icon" data-id="{{ id }}"></div>
    //       ?<div className="icon action-icon" data-id="{{ id }}"></div>
    //     </div>
    //   </div>
    //   ?<ul>{{ childNodes }}</ul>
    // </li>
    _createNodeElements(container, nodes) {
      for (let node of nodes) {
        // create the li
        const nodeContainer = document.createElement('li');
        nodeContainer.dataset.id = node.id;
        nodeContainer.dataset.label = node[this.labelField];
        nodeContainer.dataset.type = this.type(node);
        container.appendChild(nodeContainer);

        // create the node to handle flex
        const nodeElement = document.createElement('div');
        nodeElement.dataset.id = node.id;
        nodeElement.classList.add('node');
        nodeContainer.appendChild(nodeElement);

        this._createLabel(nodeElement, nodeContainer, node);

        this._createExpandIcon(nodeElement, nodeContainer, node);

        // disabled is just some styling, doesn't affect selectable
        if (this.disabledStylingIf(node)) {
          nodeContainer.classList.add('disabled');
        }

        if (node.children?.length) {
          // add the child container
          const childContainer = document.createElement('ul');
          nodeContainer.appendChild(childContainer);

          // add the child nodes to the child container
          this._createNodeElements(childContainer, node.children);

          // see if it needs to be expanded as default
          if (this.expandIf(node)) {
            this._deepExpand(nodeContainer);
          }
        }
      }
    },
    _createLabel(nodeElement, nodeContainer, node) {
      const labelContainer = document.createElement('div');
      labelContainer.classList.add('label-container');
      labelContainer.dataset.id = node.id;

      // add the type flag
      if (this.showTypeFlags) {
        this._createTypeFlag(labelContainer, node);
      }

      // add the icon
      if (this.icon(node) || this.iconTypeMap) {
        this._createLabelIcon(labelContainer, node);
      }

      // add the label text
      const label = document.createElement('span');
      label.classList.add('label');
      label.dataset.id = node.id;
      label.setAttribute('key', `${node.id}_${this.type(node)}`)
      label.innerHTML = node[this.labelField];

      labelContainer.appendChild(label);

      // add the action item
      if (this.actionIcon && this.actionIf(node)) {
        this._createActionIcon(labelContainer, node);
      }

      // add the selectable class and icon
      if (
        this.selectableIf(node)
        || this.selected.find((selected) => selected.id === String(node.id) && selected.type === this.type(node))
      ) {
        nodeContainer.classList.add('selectable');

        if (this.showCheckboxes) {
          this._createSelectIcon(labelContainer, node);
        }

        // add the event listener to handle changing selected state
        labelContainer.addEventListener('click', (event) => {
          this._toggleSelected(node.id, this.type(node));
        });
      }

      else if (this.stepThroughIf(node)) {
        nodeContainer.classList.add('step-through');

        labelContainer.addEventListener('click', (event) => {
          this._stepInto(node.id, this.type(node));
        });
      }

      // add the label container to the node
      nodeElement.appendChild(labelContainer);
    },
    _createTypeFlag(labelContainer, node) {
      const flag = document.createElement('div');
      flag.dataset.id = node.id;
      flag.classList.add('label-flag');
      flag.innerHTML = capitalize(this.type(node));

      labelContainer.prepend(flag);
    },
    _createLabelIcon(labelContainer, node) {
      const icon = document.createElement('div');
      icon.dataset.id = node.id;
      icon.classList.add('icon', 'label-icon');

      let iconCode = null;

      if (this.iconTypeMap) {
        const mappedIcon = this.iconTypeMap.find((iconType) => iconType.type === this.type(node));
        if (mappedIcon) {
          iconCode = mappedIcon.icon;
        }
      }

      if (!iconCode && this.icon(node)) {
        iconCode = this.icon(node);
      }

      icon.classList.add(`fa-${iconCode}`)

      labelContainer.prepend(icon);
    },
    _createActionIcon(labelContainer, node) {
      const action = document.createElement('div');
      action.dataset.id = node.id;
      action.className = 'icon action-icon fa-' + this.actionIcon;

      action.addEventListener('click', (event) => {
        this.$emit('node-action', node, event);
      });

      labelContainer.appendChild(action);
    },
    _createSelectIcon(labelContainer, node) {
      const icon = document.createElement('div');
      icon.dataset.id = node.id;
      icon.classList.add('icon', 'select-icon');
      icon.classList.add(this.allowMultiple ? 'fa-square' : 'fa-circle');

      labelContainer.appendChild(icon);
    },
    _createExpandIcon(nodeElement, nodeContainer, node) {
      // add an expand trigger to every node, which is hidden as default
      const icon = document.createElement('div');
      icon.dataset.id = node.id;
      icon.dataset.type = this.type(node);
      icon.classList.add('icon', 'expand-icon', 'fa-chevron-right');

      const expandable = node.children?.length;

      // only add the expandable class if it has children, which displays the icon
      if (expandable) {
        nodeContainer.classList.add('expandable');

        // add an event listener to handle changing expanded status
        icon.addEventListener('click', (event) => {
          this._toggleExpanded(nodeContainer)
        });
      }

      nodeElement.prepend(icon);
    },
    // expand this node, and move up through the chain of parent nodes to expand them as well
    _deepExpand(nodeContainer) {
      do {
        if (!nodeContainer.classList.contains('expanded')) {
          nodeContainer.classList.add('expanded');
        }

        // get the parent node element to continue up the chain
        nodeContainer = this._getParentNodeContainer(nodeContainer);
      } while(nodeContainer !== null);
    },
    _stepInto(id, type) {
      const nodeContainer = this._getNodeContainerByIdType(id, type);

      this._clearSteppedInto();

      nodeContainer.classList.add('stepped-into');

      if (nodeContainer.classList.contains('expanded')) {
        return;
      }

      this._toggleExpanded(nodeContainer);
    },
    _toggleExpanded(nodeElement) {
      this.$emit('pre-node-expansion');
      nodeElement.classList.toggle('expanded');
      this.$emit('post-node-expansion');
    },
    _toggleSelected(id, type, quietly = false) {
      this._clearSteppedInto();

      const nodeContainer = this._getNodeContainerByIdType(id, type);

      // if we can't find the node then bail out
      if (!nodeContainer) {
        return;
      }

      if (this.selectionMode === 'single') {
        this.selected = [];
        this._removeAllSelected();
      }

      const nodeData = {
        id: nodeContainer.dataset.id,
        label: nodeContainer.dataset.label,
        type: nodeContainer.dataset.type,
      };

      // remove it if it is already selected
      if (nodeContainer.classList.contains('selected')) {
        nodeContainer.classList.remove('selected');

        this.selected = this.selected.filter((node) =>
          node.id !== String(id) || node.type !== type
        );

        if (!quietly) {
          this.$emit('node-unselect', nodeData);
        }

        return this.$emit('update', this.selected);
      }

      // or add it if it isn't
      nodeContainer.classList.add('selected');
      this.selected.push(nodeData);

      if (!quietly) {
        this.$emit('node-select', nodeData);
      }

      this.$emit('update', this.selected);
    },
    _removeAllSelected(type = null) {
      const selector = type ? `li.selected[data-type='${type}']` : 'li.selected';
      this.$refs.tree.querySelectorAll(selector).forEach((nodeElement) => {
        nodeElement.classList.remove('selected');
      });
    },
    _clearSteppedInto() {
      this.$refs.tree.querySelectorAll('li.stepped-into').forEach((nodeElement) => {
        nodeElement.classList.remove('stepped-into');
      });
    },
    _getNodeContainerByIdType(id, type) {
      return this.treeContainer.querySelector(`li[data-id='${id}'][data-type='${type}']`);
    },
    _getParentNodeContainer(nodeContainer) {
      const containerElement = nodeContainer.closest('ul');
      return containerElement.closest('li');
    },
    removeUnselectableNodes() {
      const nodesToDisplay = [];
      const nodesToHideExpandIcon = [];

      // function to recursively go through each node
      // and work out if it needs to be shown/hidden
      const _getSelectables = function(nodes) {
        let returnValue = false;

        for (let node of nodes) {
          const nodeData = {
            id: String(node.id),
            type: this.type(node),
          };

          // if this node is selectable OR previously selected, show it
          if (
            this.selectableIf(node) ||
            this.selected.find((selected) => selected.id === String(node.id) && selected.type === this.type(node))
          ) {
            returnValue = true;
            nodesToDisplay.push(nodeData);
          }

          if (node.children?.length) {
            if (_getSelectables(node.children)) {
              // if any of the node's children are selectable
              // then show this node
              returnValue = true;
              nodesToDisplay.push(nodeData);
            } else {
              // if the node is selectable but none of its recursive children are
              // then hide the expand icon, because the children will be hidden
              nodesToHideExpandIcon.push(nodeData);
            }
          }
        }

        return returnValue;
      }.bind(this);

      // filter through the tree to set the arrays
      _getSelectables(this.tree);

      // then loop through each node and hide if needed
      this.$refs.tree.querySelectorAll('li').forEach((li) => {
        const id = li.dataset.id;
        const type = li.dataset.type;

        // hide element
        if (!nodesToDisplay.find((node) => String(node.id) === String(id) && node.type === type)) {
          li.classList.add('hidden');
        }
      });

      // loop through every expand icon and hide if needed
      this.$refs.tree.querySelectorAll('.expand-icon').forEach((expandIcon) => {
        const id = expandIcon.dataset.id;
        const type = expandIcon.dataset.type;

        if (nodesToHideExpandIcon.find((node) => node.id === id && node.type === type)) {
          // hide expand icon
          expandIcon.classList.add('hidden');
        }
      });
    },
    async setSelected() {
      if (!this.preSelected.length) {
        return;
      }

      const preSelected = this.selectionMode === 'single'
        ? [this.preSelected[0]]
        : this.preSelected;

      preSelected.forEach((node) => {
        this._toggleSelected(node.id, node.type, true);

        const nodeContainer = this._getNodeContainerByIdType(node.id, node.type);

        if (nodeContainer) {
          const parentNodeContainer = this._getParentNodeContainer(nodeContainer);

          // the selected node might be the root level, so won't have a parent node
          if (parentNodeContainer) {
            this._deepExpand(parentNodeContainer);
          }
        }
      });
    },
    expandAll() {
      this.treeContainer.querySelectorAll('li.expandable:not(.expanded)').forEach((nodeContainer) => {
        nodeContainer.classList.add('expanded');
      });
    },
    collapseAll() {
      this.treeContainer.querySelectorAll('li.expandable.expanded').forEach((nodeContainer) => {
        nodeContainer.classList.remove('expanded');
      });
    },
    goScrollToSelected() {
      if (this.scrollToSelected && this.selectionMode === 'single') {
        const selectedNode =  this.treeContainer.querySelector('li.selected');

        if (selectedNode) {
          selectedNode.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }
      }
    },
    resetToSelected() {
      this.treeContainer.querySelectorAll('li.expanded').forEach((nodeContainer) => {
        nodeContainer.classList.remove('expanded');
      });

      this.treeContainer.querySelectorAll('li.selected').forEach((nodeContainer) => {
        const parentNodeContainer = this._getParentNodeContainer(nodeContainer);

        if (parentNodeContainer) {
          this._deepExpand(parentNodeContainer);
        }
      });
    },
    async onFilter(value) {
      this.setLoadingState();
      // having to force a timeout so that the loading skeleton is displayed
      setTimeout(() => {
        this.doFilter(value);
        this.unsetLoadingState();
      }, 1);
    },
    async doFilter(value) {
      // if there's no filter value, remove any hidden classes across the tree
      if (value === '') {
        this.$refs.tree.querySelectorAll('.filter-hidden').forEach((li) => {
          li.classList.remove('filter-hidden');
        });
        this.resetToSelected();
        return;
      }

      const nodesToDisplay = [];
      const nodesToHideExpandIcon = [];

      // function to recursively go through each node
      // and work out if it needs to be shown/hidden based on the search term
      const _filter = function(nodes) {
        let returnValue = false;

        for (let node of nodes) {
          const nodeData = {
            id: String(node.id),
            type: this.type(node),
          };

          // get the text from the label, diving down to any nested elements put in there by translation
          const labelValue = this.treeContainer.querySelector(`.label[data-id='${node.id}']`).textContent;

          // if this node matches the search term, show it
          if (lowerCase(labelValue).includes(lowerCase(value))) {
            returnValue = true;
            nodesToDisplay.push(nodeData);
          }

          if (node.children?.length) {
            if (_filter(node.children)) {
              // if any of the node's recursive children match the search
              // then show this node
              returnValue = true;
              nodesToDisplay.push(nodeData);
            } else {
              // if the node has children but none of its recursive children match the search
              // then hide the chevron, because the children will be hidden
              nodesToHideExpandIcon.push(nodeData);
            }
          }
        }
        return returnValue;
      }.bind(this);

      // filter through the tree to set the arrays
      _filter(this.tree);

      // loop through every li element and add/remove the hidden class
      this.$refs.tree.querySelectorAll('li').forEach((li) => {
        const id = li.dataset.id;
        const type = li.dataset.type;

        if (nodesToDisplay.find((node) => node.id === id && this.type(node) === type)) {
          // show node
          li.classList.remove('filter-hidden');

          // if we're showing the node
          // also expand it in case it has any children which are shown
          if (!li.classList.contains('expanded') && li.classList.contains('expandable')) {
            li.classList.add('expanded');
          }
        } else if (!li.classList.contains('filter-hidden')) {
          // hide node
          li.classList.add('filter-hidden');
        }
      });

      // loop through every expand icon and add/remove the hidden class
      this.$refs.tree.querySelectorAll('.expand-icon').forEach((expandIcon) => {
        const id = expandIcon.dataset.id;
        const type = expandIcon.dataset.type;

        if (nodesToHideExpandIcon.find((node) => node.id === id && node.type === type)) {
          // hide expand icon
          expandIcon.classList.add('filter-hidden');
        } else if (expandIcon.classList.contains('filter-hidden')) {
          // show expand icon
          expandIcon.classList.remove('filter-hidden');
        }
      });
    },
  }
}
</script>

<style lang="scss">
div.expansive-tree.shadowed {
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.1);
}

div.expansive-tree {
  background-color: #FFF;

  .header {
    padding: 1rem 1rem 0 1rem;
  }

  .loading-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro", sans-serif;
    font-weight: 400;
    color: #6c757d;
  }

  .icon.bold::before {
    font-weight: 900;
    color: black;
    cursor: pointer;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.root {
      padding: 1rem;
    }
  }

  li {

    &.hidden {
      display: none;
    }

    &.filter-hidden {
      display: none;
    }

    ul {
      padding-left: 1rem;
    }

    &:not(.expanded) {
      ul {
        display: none;

        > li {
          display: none;
        }
      }
    }

    // EXPAND ICON
    .expand-icon {
      display: inline-block;
      margin-right: 0.75rem;
      cursor: pointer;

      &:before {
        transform: rotate(0);
        transition: 0.2s transform;
      }

      &.filter-hidden,
      &.hidden {
        visibility: hidden;
      }
    }

    &:not(.expandable) {
      > .node > .expand-icon {
        visibility: hidden;
      }
    }

    &.expanded {
      > .node > .expand-icon::before {
        transform: rotate(90deg);
        transition: 0.2s transform;
      }
    }

    // NODE
    .node {
      padding: 0.3rem;
      display: flex;
      align-items: center;
    }

    // LABEL
    .label-container {
      display: flex;
      align-items: center;
      cursor: auto;
      color: #000;
      flex: 1;
      width: 100%;

      .label {
        flex: 1;
      }

      .label-icon {
        float: left;
        margin-right: 0.5rem;

        &:before {
          font-weight: 300;
        }
      }

      .label-flag {
        float: left;
        margin-right: 0.5rem;
        padding: .05rem .3rem;
        border-radius: 0.25rem;
        background-color: #e9ecef;
        color: #000;
        font-size: 75%;
      }
    }

    &.disabled {
      .label-container {
        color: #999;
      }
    }

    &:not(.selectable):not(.step-through) {
      > .node > .label-container {
        color: #999;
      }
    }

    &.selectable,
    &.step-through {
      > .node > .label-container {
        cursor: pointer;

        > .label-icon::before {
          font-weight: 400;
        }
      }
    }

    &.stepped-into {
      > .node > .label-container {
        font-weight: 600;

        .label-icon::before {
          font-weight: 900 !important;
        }
      }
    }

    &.selected {
      > .node > .label-container {
        color: $primary;
        font-weight: 600;

        .label-icon::before {
          color: $primary;
          font-weight: 900 !important;
        }

        .label-flag {
          background-color: $primary;
          color: #FFF;
        }
      }
    }

    // ACTION ICON
    .action-icon {
      visibility: visible;
      float: right;
      margin-left: 0.5rem;
      position: relative;
      cursor: pointer;

      &:before {
        font-weight: 900;
        color: inherit;
      }
    }

    &:not(.selected):not(.stepped-into) {
      > .node > .label-container > .action-icon {
        display: none;
      }
    }

    // SELECT ICON
    .select-icon {
      visibility: visible;
      float: right;
      margin-left: 0.5rem;

      &:before {
        color: #6c757d;
        font-weight: 300;
      }
    }

    &.selected {
      > .node > .label-container > .select-icon {
        visibility: visible;

        &:before {
          content: "\f14a"; // check-square
          color: $primary;
          font-weight: 900;
        }
      }
    }
  }

  ul.single {
    li.selected {
      > .node > .label-container > .select-icon::before {
        content: "\f058";
      }
    }
  }
}
</style>

