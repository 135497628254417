import types from '../../types/budget.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_BUDGETS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Budgets collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_BUDGETS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_BUDGET
   * @summary Updates the `activeBudget` and resets the
   * `activeBudgetModifiedFields` & `activeBudgetErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Budget} budget The new Active {@link Budget}
   */
  [m.SET_ACTIVE_BUDGET](state, budget) {
    state.activeBudget = budget;
    state.activeBudgetModifiedFields = !budget || budget.id ? {} : { ...budget };
    state.activeBudgetErrors = [];
  },

  /**
   * @name m SET_ACTIVE_BUDGET_STATE
   * @summary Updates the `activeBudgetState` property which holds the current loading status of
   * the active budget based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeBudgetState The current state (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_BUDGET_STATE](state, activeBudgetState) {
    state.activeBudgetState = activeBudgetState;
  },

  /**
   * @name m SET_ACTIVE_BUDGET_ERRORS
   * @summary Updates the `activeBudgetErrors` property which holds any errors related to
   * activeBudget
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_BUDGET_ERRORS](state, errors) {
    state.activeBudgetErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_BUDGET_VALID
   * @summary Updates the `activeBudgetValid` property which holds a boolean for the validation
   * status of the `activeBudget`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeBudget` is valid.
   */
  [m.SET_ACTIVE_BUDGET_VALID](state, valid) {
    state.activeBudgetValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_BUDGET_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeBudgetModifiedFields` collection,
   * which will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeBudget` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_BUDGET_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeBudget && JSON.stringify(state.activeBudget[field]) === JSON.stringify(value)) {
      delete state.activeBudgetModifiedFields[field];
    } else {
      state.activeBudgetModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_BUDGET_MODIFIED_FIELDS
   * @summary Empties the `activeBudgetModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Budget Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_BUDGET_MODIFIED_FIELDS](state) {
    state.activeBudgetModifiedFields = {};
  },
};
