<template>
  <div>
    <b-form-input
        id="password"
        v-model.trim="v$.password.$model"
        class="field-input"
        type="password"
        :state="v$.password.$dirty ? !v$.password.$invalid : null"
    />

    <b-form-invalid-feedback v-if="v$.password.$error" :state="!!v$.password.$errors">
      <p v-if="v$.password.required.$invalid">
        {{
          $t('common.forms.validation.required', {
            field: $t('components.auth.password.password'),
          })
        }}
      </p>

      <p v-else-if="v$.password.minLength.$invalid">
        {{
          $t('common.forms.validation.minLength', {
            field: $t('components.auth.password.password'),
            min: v$.password.minLength.$params.min,
          })
        }}
      </p>

      <div v-else-if="v$.password.passwordStrength.$invalid">
        <p>
          {{
            $t('components.auth.password.validation.weak', {
              field: $t('components.auth.password.password'),
              score: passwordStrength.score,
            })
          }}
        </p>

        <div v-if="showFeedback" class="password-feedback">
          <p class="text-bold">{{ $t('components.auth.password.recommendations') }}:</p>

          <ol class="pl-3 my-0">
            <li v-for="suggestion in passwordStrength.feedback.suggestions" :key="suggestion">
              {{ suggestion }}
            </li>
            <li v-if="passwordStrength.feedback.warning">
              {{ passwordStrength.feedback.warning }}
            </li>
          </ol>
        </div>
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { minLength, required } from '@vuelidate/validators';
import { BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import asdfgh from 'asdfgh';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'Password',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BFormInput,
    BFormInvalidFeedback,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      passwordStrength: false,
    };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    showFeedback() {
      // eslint-disable-next-line max-len
      return (
          this.passwordStrength &&
          this.passwordStrength.feedback &&
          (this.passwordStrength.feedback.suggestions.length > 0 ||
              !!this.passwordStrength.feedback.warning)
      );
    },
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        passwordStrength: () => this.passwordStrength.score === 4,
      },
    };
  },
  watch: {
    async password(password) {
      this.passwordStrength = await asdfgh(password);
    },
    'v$.password.$invalid': function validationChange(invalid) {
      this.$emit('validationchange', !invalid);
    },
  },
  async mounted() {
    await asdfgh(this.password);
  },
};
</script>

<style lang="scss">
.password-feedback {
  padding: 10px;
  background: $light;
  border: 1px solid $gray-300;
  border-radius: $border-radius;
}
</style>
