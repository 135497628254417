<template>
  <b-modal
    ref="modal"
    dialog-class="change-password-modal"
    :title="$t('components.auth.login.password.changePassword')"
    scrollable
  >
    <template v-slot:modal-header>
      <div class="d-flex w-100 align-items-center">
        <b-button variant="none" @click="hide">
          {{ $t('common.forms.cancel') }}
        </b-button>
        <span class="flex-fill text-center">{{
          $t('components.auth.login.password.changePassword')
        }}</span>
      </div>
      <div class="btn-placeholder" />
    </template>

    <div v-if="success">
      <b-alert class="mb-0" variant="success" show>
        <h4 class="alert-heading">
          {{ $t('common.forms.successTitle') }}
        </h4>
        {{ $t('components.auth.changePassword.passwordChanged') }}
      </b-alert>
    </div>

    <ChangePassword
      v-else
      ref="changePassword"
      :require-old-password="requireOldPassword"
      :user-id="userId"
      @loading="onLoading"
      @validation="onValidation"
      @passwordchanged="onPasswordChange"
    />

    <template #modal-footer class="w-100">
      <b-button v-if="success" variant="primary" class="w-100" @click="hide">
        {{ $t('common.forms.close') }}
      </b-button>

      <SpinButton
        v-else
        variant="primary"
        class="w-100"
        :show-loading-msg="true"
        :loading-msg="$t('common.forms.saving')"
        :loading="isLoading"
        :disabled="!isValid"
        @click="onSubmit"
      >
        {{ $t('components.auth.login.password.changePassword') }}
      </SpinButton>
    </template>
  </b-modal>
</template>

<script>
import { BAlert, BModal, BButton } from 'bootstrap-vue';
import SpinButton from '@/ux/SpinButton.vue';
import ChangePassword from '@/ux/form/ChangePasswordForm.vue'

export default {
  name: 'ChangePasswordModal',

  components: {
    BAlert,
    BButton,
    BModal,
    SpinButton,
    ChangePassword
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },
    requireOldPassword: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isValid: false,
      success: false,
    };
  },

  methods: {
    onLoading(state) {
      this.isLoading = state;
    },

    onValidation(valid) {
      this.isValid = valid;
    },

    onSubmit() {
      this.$refs.changePassword.onSubmit();
    },

    onPasswordChange() {
      this.success = true;
    },

    /**
     * @name [PUBLIC] Shows the modal
     * @method
     * @public
     */
    show() {
      this.$refs.modal.show();
    },

    /**
     * @name [PUBLIC] Hides the modal
     * @method
     * @public
     */
    hide() {
      this.$refs.modal.hide();
      this.success = false;
    },
  },
};
</script>

<style lang="scss">
.btn-placeholder {
  width: 4.5rem;
}
</style>
